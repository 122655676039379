import React from 'react'
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import iconQuestion from '../../../assets/images/icone-question.svg'

const Oxygen = (props) => {
    return (
        <Grid item xs={12} sx={{
            backgroundColor: ({ palette: { secondary }, functions: { rgba } }) => rgba(secondary.main, 0.8),
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <img height={100} width={100} src={iconQuestion} alt='question' />
            <MKTypography mt={2} textAlign='center' variant='body1' color='white' >
                L’oxygénothérapie est indiquée dans le traitement
            </MKTypography>
            <MKTypography textAlign='center' variant='body1' color='white' >
                des insuffisances respiratoires qui se traduit par
            </MKTypography>
            <MKTypography textAlign='center' variant='body1' color='white' >
                l’incapacité des poumons à assurer l’oxygénation correcte de l’organisme.
            </MKTypography>
        </Grid>
    )
}

export default Oxygen