import React from 'react'
import Card from './../components/Card';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from 'components/MKTypography';


const Symptoms = (props) => {
    return (
        <Container >
            <Grid container >
                <Grid item xs={12} lg={6}>
                    {
                        props.symptoms.map((symtom, index) => (
                            <Card
                                description={symtom.description}
                                icon={symtom.icon}
                                title={symtom.title}
                                key={index}
                            />
                        ))
                    }
                </Grid>
                <Grid item xs={12} lg={6}>
                    {
                        props.diagnostic.map((diag, index) => (
                            <Card
                                description={diag.description}
                                icon={diag.icon}
                                title={diag.title}
                                key={index}
                            />
                        ))
                    }
                </Grid>
            </Grid>
            <Grid container px={4} mb={5}>
                <Grid item xs={12}>
                    <MKTypography variant='body2'>
                        (1) La mesure des gaz du sang : prise de sang artériel permettant,
                        par la détermination du taux sanguin d’oxygène et de gaz carbonique,
                        d’évaluer le fonctionnement de vos poumons.
                    </MKTypography>
                    <MKTypography variant='body2' mt={2}>
                        (2) Épreuve Fonctionnelle Respiratoire : permet, en soufflant dans un appareil
                        de mesure appelé spiromètre, de mesurer les volumes et les débits d’air mobilisés
                        par votre appareil respiratoire et ainsi de préciser le stade de votre maladie
                    </MKTypography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Symptoms