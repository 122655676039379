
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const ServiceCard = ({ color, count, title, description, image, ...rest }) => {
  return (
    <MKBox bgColor="white" coloredShadow="secondary" borderRadius="lg" p={2} sx={{ mx: { md: 2 }, my: { xs: 2, md: 0 } }} textAlign="center">
      <img src={image} alt={title} />
      {title && (
        <MKTypography variant="h5" mt={2} mb={1}>
          {title}
        </MKTypography>
      )}
      {description && (
        <MKTypography variant="body2" color="text">
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}
ServiceCard.defaultProps = {
  color: "info",
  description: "",
  title: "",
};

ServiceCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ServiceCard;
