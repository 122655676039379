import React from 'react'
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
const Card = (props) => {
    return (
        <MKBox
            key={props.description}
            borderRadius="lg"
            display='flex'
            alignItems='center'
            mx={2}
            sx={{
                my: 2, py: 1, px: 2,
                backgroundColor: '#ddd',
            }}

        >
            {
                props.icon &&
                <img height={40} width={40} src={props.icon} alt={props.description} />
            }
            <MKTypography
                variant={props.title ? 'h4' : 'body2'}
                textAlign={'center'}
                mx={2}
            >
                {props.description}
            </MKTypography>
        </MKBox>
    )
}

export default Card