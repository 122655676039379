import React from 'react'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/background.png";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
    const navigate = useNavigate();

    return (
        <MKBox my={4}>
            <Grid container>
                <Grid item xs={12}>
                    <MKBox
                        minHeight="30vh"
                        width="100%"
                        sx={{
                            backgroundImage: `url(${bgImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "0% 20%",
                            display: "grid",
                            placeItems: "center",
                        }}
                    >
                        <Container>
                            <Grid container item xs={12} display="flex" alignItems='center' flexDirection="column" >
                                <MKTypography
                                    variant="h3"
                                    textAlign="center"
                                    mb={1}
                                >
                                    Où sommes-nous ?
                                </MKTypography>
                                <MKTypography
                                    variant="body1"
                                    textAlign="center"
                                >
                                    Basé à Ennasr, Ariana
                                </MKTypography>
                                <MKTypography
                                    variant="body1"
                                    mb={2}
                                >
                                    2A Medical intervient dans
                                    tout le territoire Tunisien.
                                </MKTypography>
                                <MKButton onClick={() => navigate('contact')} variant="gradient" color="info">
                                    Nous contacter
                                </MKButton>
                            </Grid>
                        </Container>
                    </MKBox>
                </Grid>
            </Grid>
        </MKBox >
    )
}

export default ContactUs