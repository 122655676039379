import React from 'react'
import Card from './../components/Card';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from 'components/MKTypography';
import MKBox from "components/MKBox";

const INDICES = [
    'SAOS léger pour un IAH compris en 5 et 15/heure',
    'SAOS modéré pour un IAH compris entre 15 et 30/heure',
    'SAOS sévère pour un IAH supérieur à 30/heure',
]


const FACTORS = [
    'L’ obésité : risque multiplié par 7.',
    'L’âge: risque entre 2 et 3 fois plus élevé après 65 ans',
    'Le sexe : les hommes sont 2 à 3 fois plus touchés que les femmes',
]


const Symptoms = (props) => {
    return (
        <Container >
            <Grid container >
                <Grid item xs={12} lg={6}>
                    {
                        props.symptoms.map((symtom, index) => (
                            <Card
                                description={symtom.description}
                                icon={symtom.icon}
                                title={symtom.title}
                                key={index}
                            />
                        ))
                    }
                </Grid>
                <Grid item xs={12} lg={6}>
                    {
                        props.consequences.map((consequence, index) => (
                            <Card
                                description={consequence.description}
                                icon={consequence.icon}
                                title={consequence.title}
                                key={index}
                            />
                        ))
                    }
                </Grid>
            </Grid>
            <Grid container px={4} mb={5}>
                <Grid item xs={12}>
                    <MKTypography variant='body2'>
                        <b>L'indice d'apnées/hypopnées</b> (IAH) permet d'évaluer la sévérité du SAOS (Syndrome d'apnées obstructives du sommeil) :
                    </MKTypography>
                    <MKBox component="ul" mx={6} color='white'>
                        {
                            INDICES.map(indice => (
                                <MKBox key={indice} component="li" >
                                    <MKTypography
                                        variant='body2'
                                    > {indice}</MKTypography>
                                </MKBox>
                            ))
                        }
                    </MKBox>
                    <MKTypography variant='body2' mt={2}>
                        L’apnée du sommeil peut toucher tout le monde. Même les enfants sont concernés, tout comme les femmes ménopausées, les femmes enceintes, etc. Mais il existe tout de même des facteurs qui augmentent considérablement le risque de développer ce syndrome
                    </MKTypography>
                    <MKBox component="ul" mx={6} color='white'>
                        {
                            FACTORS.map(factor => (
                                <MKBox key={factor} component="li" >
                                    <MKTypography
                                        variant='body2'
                                    > {factor}</MKTypography>
                                </MKBox>
                            ))
                        }
                    </MKBox>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Symptoms