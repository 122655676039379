import React from 'react'
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/bgwho.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const TEAM_MEMBERS = [
    'D\'un Dirigeant',
    'D\'un référant respiratoire',
    'D\'un pharmacien',
    'De techniciens respiratoires',
    'De personnel administratif'
]

const BusinessInfo = (props) => {
    return (
        <MKBox
            minHeight="90vh"
            width="100%"

            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                // placeItems: 'center',
            }}
        >
            <Container>
                <Grid container item xs={12} lg={12} display='flex' flexDirection='column'>
                    <MKTypography
                        variant="h3"
                        mb={1}
                        mt={18}
                        ml={{ xs: 3, md: 4 }}
                        color='primary'
                    >
                        QUI SOMMES-NOUS ?
                    </MKTypography>
                    <MKTypography
                        variant="body1"
                        mt={1}
                    >
                        2A Medical est une société spécialisée dans la prestation médico-technique de la prise en charge à domicile des patients souffrants
                        d'apnées et de pathologies respiratoires.
                    </MKTypography>
                    <MKTypography
                        variant="body1"
                        mb={1}
                    >
                        Notre société est basée à Tunis.
                    </MKTypography>
                    <MKTypography
                        variant="body1"
                        mb={1}
                    >
                        Notre équipe est composée :
                    </MKTypography>
                    <MKBox component="ul" mx={6} color='white'>
                        {
                            TEAM_MEMBERS.map(member => (
                                <MKBox key={member} component="li" >
                                    <MKTypography
                                        textTransform="capitalize"
                                    > {member}</MKTypography>
                                </MKBox>
                            ))
                        }
                    </MKBox>
                    <MKTypography
                        variant="body1"
                        mt={1}>
                        Cette organisation permet non seulement de répondre à l'ensemble des demandes
                        de nos médecins mais également d'accompagner les patients qui nous font confiance.
                    </MKTypography>
                    <MKTypography
                        variant="body1"
                        mt={1}>
                        2A Medical propose une solution adaptée à chaque patient et s'occupe de toutes vos demandes administratives.
                        Nous assurons un suivi rigoureux et vous accompagnons tout au long de votre traitement tout en entretenant une collaboration étroite avec votre prescripteur.
                    </MKTypography>

                </Grid>
            </Container>
        </MKBox>
    )
}

export default BusinessInfo