import React from 'react'
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import iconQuestion from '../../../assets/images/icone-question.svg'

const Apnea = (props) => {
    return (
        <Grid item xs={12} sx={{
            backgroundColor: ({ palette: { secondary }, functions: { rgba } }) => rgba(secondary.main, 0.8),
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <img height={100} width={100} src={iconQuestion} alt='question' />
            <MKTypography mt={2} textAlign='center' variant='body1' color='white' >
                Ronflements, somnolence, manque d’énergie ?
            </MKTypography>
            <MKTypography textAlign='center' variant='body1' color='white' >
                L’apnée est un trouble du sommeil courant, qui se manifeste par des pauses respiratoires durant le sommeil.
            </MKTypography>
            <MKTypography textAlign='center' variant='body1' color='white' >
                On parle d’apnée lorsque la respiration s’arrête durant au moins 10 secondes.
            </MKTypography>
        </Grid>
    )
}

export default Apnea