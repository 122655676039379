import React from 'react'
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/ap-image.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { CONSEQUENCES, SYMPTOMS, INFO } from './data/data';
import Apnea from './sections/Apnea';
import Symptoms from './sections/Symptoms';
import Treatment from './sections/Treatment';
import Info from './sections/Info';

const SleepApnea = () => {
    return (
        <Grid pt={15}>

            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "20%",
                    backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0, rgba(0,0,0,0.2) 0),url(${bgImage})`,
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={12} justifyContent='center'>
                        <MKTypography
                            variant="h2"
                            mb={1}
                            mt={15}
                            color='white'
                            mx={2}
                            textAlign='center'
                        >
                            APNÉE DU SOMMEIL
                        </MKTypography>
                        <MKTypography
                            variant="body1"
                            mt={1}
                            mx={2}
                            color='white'
                        >
                            2A Medical aide les patients atteints d’apnée du sommeil à bien vivre leur traitement par PPC au quotidien.
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Apnea />
            <Symptoms symptoms={SYMPTOMS} consequences={CONSEQUENCES} />
            <Treatment />
            <Info info={INFO} />
        </Grid>

    )
}

export default SleepApnea