import React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Services from "pages/Presentation/sections/Services";
import Info from "pages/Presentation/sections/Info";
import bgImage from "assets/images/homepageBG.jpeg";
import HealthCare from './sections/HealthCare';
import logo from '../../assets/images/2a-medical-home.png'
import { Fade } from "react-reveal";
import ContactUs from './sections/ContactUs';

const Presentation = () => {
  return (
    <React.Fragment>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0, rgba(0,0,0,0.3) 0),url(${bgImage})`,

        }}
      >
        <Container>
          <Grid container item xs={12} lg={8} justifyContent="center" mx="auto">
            <Fade top duration={1500}>
              <img width={280} src={logo} alt='2a-medical' />
            </Fade>
            <Fade bottom duration={1500}>

              <MKTypography
                variant="h3"
                color="white"
                textAlign="center"
                mt={1}
              >
                Votre partenaire santé à domicile spécialisé dans la prise en charge des patients souffrant de pathologies respiratoire.
              </MKTypography>
            </Fade>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          py: 2,
          mx: { xs: 2, lg: 8 },
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          mt={-12}
        >
          <Services />
        </Grid>
        <Grid item textAlign="center" display='flex' justifyContent='center' xs={12}>
          <MKTypography
            variant="h3"
            mt={4}
            mb={2}
            pb={2}
            sx={{
              width: '100%',
              borderBottom: '1px solid '
            }}
          >Home Care</MKTypography>
        </Grid>
        <Info />
        <HealthCare />
        <ContactUs />
      </Card>

    </React.Fragment>
  );
}

export default Presentation;
