
import React from 'react';
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import { Fade } from "react-reveal";

const InfoCard = ({ image, description, reversed, title, route, ...rest }) => {
  const imageTemplate = (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      minHeight="5rem"
      sx={{
        overflow: "hidden",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
          cursor: "pointer"
        },
      }}
      {...rest}
    >
      <MKBox
        component="img"
        src={image}
        alt={description}
        width="100%"
        my="auto"
      />
    </MKBox>
  );

  return (
    <Grid container my={2}>
      {
        !reversed ?
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <Fade left duration={1500}>
                {
                  imageTemplate
                }
              </Fade>
            </Grid>
            <Grid item xs={12} md={6} sx={{ px: { md: 2, lg: 8 }, py: { xs: 2 } }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'>
              <Fade right duration={1500}>
                <MKTypography color='primary' mb={2} component={Link} to={route}
                  sx={{
                    "&": {
                      textDecoration: "underline",
                      cursor: "pointer"
                    },
                  }}
                >{title}</MKTypography>
                <MKTypography variant="body2">
                  {description}
                </MKTypography>
              </Fade>
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            <Grid item xs={12} md={6} sx={{ px: { md: 2, lg: 8 }, py: { xs: 2 } }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'>
              <Fade left duration={1500}>

                <MKTypography color='primary' mb={2} component={Link} to={route}
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer"
                    },
                  }}
                >{title}</MKTypography>
                <MKTypography variant="body2">
                  {description}
                </MKTypography>
              </Fade>
            </Grid>
            <Grid item xs={12} md={6}>
              <Fade right duration={1500} >
                {
                  imageTemplate
                }
              </Fade>
            </Grid>

          </React.Fragment>
      }

    </Grid>
  );
}

// Setting default props for the InfoCard
InfoCard.defaultProps = {
  description: "",
  reversed: false
};

// Typechecking props for the InfoCard
InfoCard.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string,
  reversed: PropTypes.bool,
};

export default InfoCard;
