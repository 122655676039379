import React from 'react'
import Grid from "@mui/material/Grid";
import apneaImage from '../../../assets/images/apnee-du-sommeil-apc.jpg'
import MKTypography from 'components/MKTypography';
import iconTreatment from '../../../assets/images/icone-traitement.svg'

const Treatment = (props) => {
    return (
        <Grid item xs={12}
            sx={{
                backgroundColor: ({ palette: { secondary }, functions: { rgba } }) => rgba(secondary.main, 0.8),
            }}
        >
            <Grid container>

                <Grid item xs={12} md={6} sx={{
                    display: 'flex'
                }}>
                    <img width='100%' src={apneaImage} alt='apnee-du-sommail' />

                </Grid>
                <Grid item xs={12} md={6}
                    mt={{ xs: 2 }}
                    mb={{ xs: 2 }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <img src={iconTreatment} height={60} alt='Traitement' />
                    <MKTypography mt={2} variant='h3' color='white'>
                        QUEL TRAITEMENT ?

                    </MKTypography>
                    <MKTypography variant='body1' textAlign='center' color='white'>
                        La ventilation à pression positive continue (ou PPC)
                    </MKTypography>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default Treatment