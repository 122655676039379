import React from 'react'
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/femme.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { DIAGNOSTIC, SYMPTOMS, INFO } from './data/data';
import Oxygen from './sections/Oxygen';
import Symptoms from './sections/Symptoms';
import Treatment from './sections/Treatment';
import Info from './sections/Info';
import Equipment from './sections/Equipment';

const OxygenTherapy = (props) => {
    return (
        <Grid pt={15}>
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0, rgba(0,0,0,0.4) 0),url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",

                }}
            >
                <Container>
                    <Grid container item xs={12} lg={12} justifyContent='center'>
                        <MKTypography
                            variant="h2"
                            mb={1}
                            mt={15}
                            color='white'
                            mx={2}
                        >
                            INSUFFISANCE RESPIRATOIRE
                        </MKTypography>
                        <MKTypography
                            variant="body1"
                            mt={1}
                            mx={2}
                            color='white'
                        >
                            2A Medical accompagne à domicile les patients atteints d’insuffisance respiratoire et
                            réalise un suivi de leur traitement par oxygène médical.
                        </MKTypography>
                    </Grid>

                </Container>
            </MKBox>
            <Oxygen />
            <Symptoms
                symptoms={SYMPTOMS}
                diagnostic={DIAGNOSTIC}
            />
            <Treatment />
            <Info info={INFO} />
            <Equipment />
        </Grid>
    )
}

export default OxygenTherapy