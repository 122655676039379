import React from 'react'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/healthcare.jpg";
import MKBox from "components/MKBox";

const HealthCare = (props) => {
    return (
        <MKBox my={4}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <MKBox
                            minHeight="30vh"
                            width="100%"
                            sx={{
                                backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0, rgba(0,0,0,0.4) 0),url(${bgImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "0% 20%",
                                display: "grid",
                                placeItems: "center",
                                backgroundAttachment: "fixed",
                            }}
                        >
                            <Container>
                                <Grid container item xs={12} display="flex" flexDirection="column" >
                                    <MKTypography
                                        variant="h2"
                                        color="white"
                                        textAlign="center"
                                    >
                                        We care about your life.
                                    </MKTypography>
                                </Grid>
                            </Container>
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox >
    )
}

export default HealthCare