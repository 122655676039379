
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import data from "pages/Presentation/sections/data/data";
import InfoCard from '../components/InfoCard';

const Info = () => {
  const renderData = data.map(({ image, description, title, reversed, route }) => (
    <Grid item xs={12} key={description}>
      <InfoCard
        reversed={reversed}
        image={image}
        title={title}
        route={route}
        description={description}
        display="grid"
        minHeight="auto" />
    </Grid>
  ));

  return (
    <MKBox>
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mt: 3, px: { xs: 0, lg: 2 } }}>
            {renderData}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Info;
