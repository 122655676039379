import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import MKBox from "components/MKBox";
import theme from "assets/theme";
import Presentation from "pages/Presentation";
import routes from "routes";
import footerRoutes from "footer.routes";
import Contact from 'pages/Contact';
import TopHeader from 'components/TopHeader';
import BusinesInfo from 'pages/BusinessInfo';
import SleepApnea from 'pages/SleepApnea';
import OxygenTherapy from 'pages/OxygenTherapy';

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>

      <CssBaseline />
      <TopHeader />
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/whoarewe" element={<BusinesInfo />} />
        <Route path="/sleep-apnea" element={<SleepApnea />} />
        <Route path="/oxygen-therapy" element={<OxygenTherapy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <MKBox pt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </ThemeProvider>

  );
}
export default App; 