import HomeIcon from '@mui/icons-material/Home';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FeedIcon from '@mui/icons-material/Feed';
import Contact from 'pages/Contact';
import Presentation from 'pages/Presentation';
import BusinessInfo from 'pages/BusinessInfo';
import SleepApnea from 'pages/SleepApnea';
import OxygenTherapy from 'pages/OxygenTherapy';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

const routes = [
  {
    name: "Accueil",
    route: '/',
    component: <Presentation />,
    icon: <HomeIcon />,
  },

  {
    name: "Home Care",
    icon: <HealthAndSafetyIcon />,
    collapse: [
      {
        name: "Apnée du sommeil",
        route: '/sleep-apnea',
        component: <SleepApnea />
      },
      {
        name: "Oxygénothérapie",
        route: '/oxygen-therapy',
        component: <OxygenTherapy />
      },
    ],
  },
  {
    name: "Health Care",
    // route: '',
    // component: <BusinessInfo />,
    icon: <FeedIcon />,

  },
  {
    name: "A propos",
    route: 'whoarewe',
    component: <BusinessInfo />,
    icon: <QuestionMarkIcon />,

  },
  {
    name: "Contact",
    route: 'contact',
    component: <Contact />,
    icon: <ContactPageIcon />,
  },
];

export default routes;
