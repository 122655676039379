import React from 'react'
import Container from "@mui/material/Container";
import InfoCard from '../components/InfoCard'

const Info = (props) => {
    return (
        <Container>
            {
                props.info.map(info => (
                    <InfoCard
                        key={info.title}
                        title={info.title}
                        description={info.description}
                        reversed={info.reversed}
                        image={info.image}
                        imageTitle={info.imageTitle}
                        imageDescription={info.imageDescription}
                        subtitle={info.subtitle}
                        subDescription={info.subDescription}
                        icon={info.icon}
                        insctructions={info.instructionsIcons}
                    />

                ))
            }
        </Container>
    )
}

export default Info