import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const DefaultFooter = ({ content }) => {
  const { brand, socials, menus, copyright } = content;

  return (
    <MKBox component="footer" bgColor='dark'>
      <Container>
        <Grid container spacing={6} justifyContent='space-between'>
          <Grid item xs={12} md={6} sx={{ mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="9rem" mb={1} ml={-2} />
              </Link>
              <MKTypography color='white' variant="body2">
                Votre partenaire santé à domicile spécialisé dans la prise en chage des patients souffrant de pathologies respiratoire.
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems='center' mt={2}>
              <MKTypography color='white' variant="body2" mr={2}>
                Vous pouvez nous suivre sur
              </MKTypography>
              {socials.map(({ icon, link }, key) => (
                <MKTypography color='white'
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={4} xl={2} sx={{ mb: 3 }}>
              <MKTypography color='white'
                display="block"
                variant="body2"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, title }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {
                      route ?
                        <MKTypography
                          color='white'
                          variant="button"
                          fontWeight="light"
                          textTransform="capitalize"
                          to={route}
                          component={Link}
                        >
                          {name}
                        </MKTypography>
                        :
                        <MKTypography
                          color='white'
                          variant="button"
                          fontWeight={title ? 'bold' : 'light'}
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>


                    }
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid container display='flex' justifyContent='center'>
            <Grid item xs={12} display='flex' justifyContent='flex-end' sx={{ mb: 3, justifyContent: { xs: 'flex-end', md: 'center' } }}>
              {copyright}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox >
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
