import React from 'react'
import InfoCard from '../components/InfoCard';
import Container from "@mui/material/Container";


const Info = (props) => {
    return (
        <Container>
            {
                props.info.map(info => (
                    <InfoCard
                        key={info.title}
                        title={info.title}
                        description={info.description}
                        reversed={info.reversed}
                        image={info.image}
                        elements={info.elements}
                    />

                ))
            }
        </Container>
    )
}

export default Info