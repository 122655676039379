import FacebookIcon from "@mui/icons-material/Facebook";
import MKTypography from "components/MKTypography";
import logo from "assets/images/2a-medical-alt.png";

const date = new Date().getFullYear();

const DefaultFooter = {
  brand: {
    name: "2A Medical",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon fontSize='medium' />,
      link: "https://www.facebook.com/azizabdelhayaloui",
    },

  ],
  menus: [
    {
      name: "contact",
      items: [
        { name: `Email`, title: true },
        { name: `Info@2amedical.com.tn` },
        { name: "Téléphone", title: true },
        { name: "70832062" },
        { name: "Fax", title: true },
        { name: "70832360" },
        { name: "Mobile", title: true },
        { name: "+ 216 21 69 11 11" },
      ],
    },

    {
      name: "Quick Link",
      items: [
        { name: "apnée du sommeil", route: 'sleep-apnea' },
        { name: "oxygenotherapie", route: 'oxygen-therapy' },
      ],
    },

  ],
  copyright: (
    <MKTypography color='white' variant='body2' fontWeight="regular">
      All rights reserved. Copyright &copy; {date} - {" "} 2A Medical.
    </MKTypography>
  ),
};

export default DefaultFooter