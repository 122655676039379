
import React from 'react';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import smoking from '../../../assets/images/icone-pas-de-cigarette.svg';
import fire from '../../../assets/images/icone-flamme.svg';
import aerosol from '../../../assets/images/icone-aerosol.svg';
import creme from '../../../assets/images/icone-creme-gras.svg';
import ventilo from '../../../assets/images/icone-ventilo.svg';

const INSTRUCTIONS = [
    `Ne pas fumer à proximité de l'oxygène à usage médical.`,
    `Ne pas stocker, ni utiliser le matériel à proximité de toute source de flamme 
    (cheminées, bougies, cuisinière, étincelles...).`,
    `Ne pas placer le matériel à moins de 2 mètres d'une source de chaleur (chauffage).`,
    `Ne pas utiliser de bombes aérosols à proximité de l'oxygène à usage médical..`,
    `Éloigner tout corps gras de la source d'oxygène à usage médical : limiter l'utilisation de crème à proximité des lunettes, 
    manipuler le matériel avec les mains propres..`,
    `Aérer votre habitation quotidiennement pendant quelques minutes..`,

]

const INSTRUCTIONS_ICONS = [
    smoking,
    fire,
    aerosol,
    creme,
    ventilo
]

const ACCORDION_DATA = [
    {
        title: 'Le concentrateur d’oxygène fixe',
        description: `Le concentrateur d’oxygène est un appareil électrique qui produit 
        de l’oxygène médical à partir de l’air ambiant. Il n’a donc pas besoin d’être rechargé en oxygène. 
        Il permet de vous fournir de l’oxygène médical à votre domicile. Il se branche sur une prise de courant. 
        Un interrupteur permet de l’allumer et de l’éteindre. Le réglage du débit prescrit par votre médecin se 
        fait en tournant une molette et est réalisé et contrôlé par votre technicien d’assistance respiratoire.`
    },
    {
        title: 'Les concentrateurs d’oxygène mobiles',
        description: `
        Les concentrateurs d’oxygène mobiles sont de petits concentrateurs qui permettent de vous fournir l’oxygène
        médical lors de vos déplacements à l’intérieur ou à l’extérieur de votre domicile. Ils fonctionnent sur batterie,
        sur secteur ou sur prise allume-cigare de votre voiture. Il en existe différents modèles. Votre médecin vous a
        prescrit le concentrateur mobile adapté à vos besoins. Ils sont soit portables en bandoulière ou en sac à dos 
        pour une petite déambulation, soit transportables sur chariot pour une plus grande déambulation. Ils pèsent entre 
        1,3 et 5 Kg suivant les modèles. Pour économiser l’énergie et ainsi avoir une meilleure autonomie, les 
        concentrateurs mobiles délivrent de l’oxygène en mode pulsé : l’appareil envoie un bolus d’oxygène lorsqu’il 
        détecte une inspiration. Les modèles transportables peuvent également fonctionner en mode continu : 
         l’appareil envoie de l’oxygène en permanence au débit réglé.
        `
    },
    {
        title: 'Le système de remplissage de bouteille à oxygène médicinal',
        description: `
        Ce système est conçu pour vous fournir de l’oxygène médical à votre domicile et remplir des bouteilles pour vous
         permettre de déambuler. Le système est composé d’un concentrateur d’oxygène, un appareil de remplissage en oxygène 
         (un compresseur) et de 2 bouteilles d’oxygène, (plus si nécessaire). Le concentrateur prélève l’air ambiant 
         et alimente le système de remplissage en oxygène médical. Le système de remplissage comprime l’oxygène dans une 
         bouteille à une pression élevée. Cette bouteille peut alors être utilisée pour vos déplacements à l’intérieur ou 
         à l’extérieur de votre domicile`
    },
    {
        title: 'L’oxygène médicinal à l’état liquide et à très basse température (- 183 °C)',
        description: `À très basse température (- 183 °C), il est contenu, à domicile, dans un réservoir fixe 
            (cuve de 45 litres) qui délivre, après passage dans un serpentin réchauffeur, de l’oxygène à l’état gazeux.
             Lui est associé un réservoir portable (de 0,4 à 1,2 litres), que vous remplissez à partir du réservoir 
             fixe et qui vous permet les déplacements à l’intérieur ou à l’extérieur de votre domicile.`
    },
    {
        title: 'Les bouteilles d’oxygène médicinal, de couleur blanche, qui renferment de l’oxygène médicinal, sous forme gazeuse',
        description: `De couleur blanche, les bouteilles renferment de l’oxygène médicinal, sous forme gazeuse, 
        comprimé à une pression de 200 bars. Elles sont utilisées pour votre déambulation occasionnelle (< 1h/jour) 
        à l’aide d’un chariot, d’une sacoche en bandoulière ou d’un sac à dos et comme poste de secours éventuel en 
        complément du poste fixe qui délivre votre oxygène.`
    },
]

const Equipment = (props) => {
    return (
        <Container>
            <Grid container my={5} display='flex' justifyContent='center'  >
                {
                    <React.Fragment>
                        <Grid item xs={12} md={4} display='flex'
                            flexDirection='column'
                            justifyContent='flex-start'
                            alignItems='center'>
                            <MKTypography color='primary' mb={2}>
                                L’équipement
                            </MKTypography>
                            <MKTypography variant="body2" mb={3}>
                                Les techniciens d’assistance respiratoire habilités par Médopale interviennent à
                                votre domicile pour mettre en place le matériel prescrit par votre médecin,
                                dans le respect de la législation en vigueur, parmi ces différentes sources :
                            </MKTypography>
                            {
                                ACCORDION_DATA.map(acc => (
                                    <Accordion key={acc.title}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <MKTypography>{acc.title}</MKTypography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <MKTypography>
                                                {acc.description}
                                            </MKTypography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }

                        </Grid>
                        <Grid item xs={12} md={6} sx={{ pl: { md: 2, lg: 8 } }}
                            display='flex'
                            flexDirection='column'
                            justifyContent='flex-start'
                            alignItems='center'>
                            <MKTypography color='primary' mb={2}
                            >Règles de sécurité</MKTypography>
                            <MKTypography variant="body2">
                                Rappel de quelques règles, non exhaustives, de sécurité concernant l'utilisation de l'oxygène à usage médical
                            </MKTypography>
                            <MKBox component="ul" mx={6} color='white'>
                                <Grid display='flex' justifyContent='center'>
                                    {
                                        INSTRUCTIONS_ICONS.map((icon, index) => (
                                            <img height={40} key={index} style={{ margin: '5px' }} src={icon} alt='instruction' />
                                        ))
                                    }
                                </Grid>
                                {
                                    INSTRUCTIONS.map(inst => (
                                        <MKBox key={inst} component="li" >
                                            <MKTypography
                                                variant="body2"
                                                fontWeight="light"
                                            > {inst}</MKTypography>
                                        </MKBox>
                                    ))
                                }
                            </MKBox>
                        </Grid>
                    </React.Fragment>

                }

            </Grid>
        </Container>

    )
}

export default Equipment