
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import earIcon from '../../../assets/images/icone-ecoute.png';
import proximityIcon from '../../../assets/images/icone-proximite.png';
import qualityIcon from '../../../assets/images/icone-qualite.png';
import ServiceCard from '../components/ServiceCard';
import { Fade } from "react-reveal";

const Services = () => {
  return (
    <MKBox py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }} >
          <Grid item xs={12} md={4} display='flex' alignItems='stretch'>
            <Fade bottom duration={2000} distance="40px">
              <ServiceCard
                image={proximityIcon}
                title="Proximité"
                description="2A Medical, c’est d’abord une PME indépendante qui s’appuie sur un réseau national. Situé sur la Côte d’Opale, 2A Medical est constitué d’une équipe à taille humaine qui vous garantit un service de proximité"
              />
            </Fade>
          </Grid>
          <Grid item xs={12} md={4} display='flex' alignItems='stretch'>
            <ServiceCard
              image={earIcon}
              title="Écoute"
              description="Disponible 24/24h et 7/7j, l’équipe 2A Medical est à votre écoute pour répondre avec réactivité à l’ensemble de vos demandes et adapter vos besoins au quotidien"
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex' alignItems='stretch'>
            <Fade bottom duration={2000} distance="40px">
              <ServiceCard
                image={qualityIcon}
                title="Qualité"
                description="2A Medical vous fournit un service et un accompagnement de qualité. Présent tout au long du traitement, 2A Medical contribue à améliorer la qualité de vie du patient avec des technologies de pointe."
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Services;
