
import React from 'react';
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/contactbg.jpg";
import Container from "@mui/material/Container";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import MapIcon from '@mui/icons-material/Map';

const Contact = () => {
  return (
    <React.Fragment>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.0) 0, rgba(0,0,0,0.3) 0),url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} flexDirection='column' justifyContent='center'>

            <MKTypography
              variant="h2"
              color="white"
              mb={1}
              textAlign="center"
            >
              Nous sommes à votre écoute pour toute question.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Grid container >
        <Grid item xs={12} md={6} xl={6} sx={{ mt: { xs: 2, md: 0 } }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <MKTypography variant='body2' >
            Veuillez remplir le formulaire ci-dessous.
          </MKTypography>
          <MKTypography variant='body2' textAlign='center'>
            Nous nous engageons à vous répondre dans les meilleurs délais et à vous apporter une réponse personnalisée.
          </MKTypography>
          <Grid>
            <Grid display='flex' alignItems='center' sx={{ mt: { xs: 2 } }}>
              <MapIcon />
              <MKTypography mx={1} variant='body2' textAlign='center'>
                Résidence Amira  Avenue Hedi Nouira Enaser2, 2037 Ariana
              </MKTypography>
            </Grid>
            <Grid display='flex' alignItems='center' sx={{ mt: { xs: 2 } }}>
              <EmailIcon />
              <MKTypography mx={1} variant='body2' textAlign='center'>
                Info@2amedical.Com.Tn
              </MKTypography>
            </Grid>
            <Grid display='flex' alignItems='center' sx={{ mt: { xs: 2 } }}>
              <PhoneIcon />
              <MKTypography mx={1} variant='body2' textAlign='center'>
                +216 21 69 11 11
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          xl={5}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            mt={{ xs: 10, sm: 18, md: 10 }}
            mb={{ xs: 10, sm: 18, md: 10 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
              display="flex"
              justifyContent="center"
            >
              <MKTypography variant="h3" color="white">
                NOUS CONTACTER
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Nous laisser un message.
              </MKTypography>
              <MKBox width="100%" autocomplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Votre Nom"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Votre Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <MKInput
                      variant="standard"
                      label="Sujet de votre message"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Comment pouvons nous vous aider?"
                      placeholder="Veuillez décrire votre problème"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Envoyer votre message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <Grid
        container justifyContent='center' display="flex"
      >
        <Grid item xs={12} lg={10}>
          <iframe
            title='position'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1596.2259258405072!2d10.152701408201011!3d36.85560189498428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd334e90c6d3a1%3A0x92e0fefbbcdfb7a8!2sEpargne%20Immobili%C3%A8re!5e0!3m2!1sfr!2stn!4v1649896286018!5m2!1sfr!2stn"
            width="100%" height="450" style={{ border: 'none' }}
            loading="lazy"
          ></iframe>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Contact;
