import essoufflement from '../../../assets/images/icone-essoufflement.svg';
import fatigue from '../../../assets/images/icone-fatigue.svg';
import headAche from '../../../assets/images/icone-maux-de-tete.svg';


import stetoscope from '../../../assets/images/icone-stetoscope.svg';
import blood from '../../../assets/images/icone-goutte-sang.svg';
import walk from '../../../assets/images/icone-homme-qui-marche.svg';
import scanner from '../../../assets/images/icone-scanner.svg';

import oxygen from '../../../assets/images/concentrateurs-oxygene.jpg';
import oxygenAcc from '../../../assets/images/oxygénotherapie-accompagnement.jpg';
import picture from '../../../assets/images/xavier.jpg';


export const SYMPTOMS = [
  {
    description: 'QUELS SYMPTÔMES ?',
    title: true
  },
  {
    description: 'L’essoufflement est le symptôme principal.',
    icon: essoufflement
  },
  {
    description: `Une grande fatigue.`,
    icon: fatigue
  },
  {
    description: `Des maux de têtes.`,
    icon: headAche,
  },
  {
    description: `Mais aussi cyanose des extrémités (coloration bleue des doigts, lèvres, oreilles).`,
    icon: ''
  },
  {
    description: `Cette insuffisance respiratoire peut être aigüe, d’installation brutale ou plus souvent chronique,
  d’apparition progressive.`,
    icon: ''
  },

];

export const DIAGNOSTIC = [
  {
    description: 'QUEL DIAGNOSTIC ?',
    title: true
  },
  {
    description: 'Le diagnostic d’une insuffisance respiratoire repose sur:',
    icon: ''
  },
  {
    description: `La consultation du médecin pour rechercher les signes cliniques.`,
    icon: stetoscope
  },
  {
    description: `La mesure des gaz du sang (1).`,
    icon: blood,
  },
  {
    description: `La réalisation d’une Épreuve Fonctionnelle Respiratoire (2).`,
    icon: walk
  },
  {
    description: `La réalisation d’examens complémentaires tel qu’un scanner thoracique.`,
    icon: scanner
  },
]


export const INFO = [
  {
    title: `
    Selon votre pathologie et son stade, votre âge, votre mode de vie, votre médecin vous prescrira
     la source d’oxygène la plus adaptée à vos besoins en tenant compte notamment :
    `,
    elements: [
      `Du type et du mode d’administration de l’oxygène à usage médical.`,
      `De votre besoin ou non d’oxygène à l’effort.`,
      `Des caractéristiques techniques des appareils en terme d’autonomie, de poids, d’encombrement et de bruit`,
    ],
    image: oxygen,
    reversed: true

  },
  {
    title: `
    Spécialisés dans l’assistance respiratoire à domicile, 
    les techniciens de Médopale vous accompagnent 
    et mettent en place le traitement à domicile,
    conformément à la prescription médicale établie :
    `,
    elements: [
      `Tout au long du traitement, des visites de contrôle régulières
       (suivant la fréquence de la LPP ou plus si besoin) sont effectuées.`,

      `Lors de ces visites, le technicien d’assistance respiratoire spécialisé échange 
      avec vous sur votre traitement, 
      entretient le matériel et renouvelle les consommables`,

      `À l’issue de ces visites, un compte-rendu détaillé est adressé à votre prescripteur.`,
      `A la demande du prescripteur des analyses de contrôle à domicile peuvent être réalisé, 
      (oxymétrie sur 12h ou 24h, Capnographie ).`,
    ],
    image: oxygenAcc
  },
  {
    title: `
   Les bénéfices possibles
    `,
    description: 'Amélioration de votre qualité de vie :',
    elements: [
      `Diminution de votre fatigue avec plus d’entrain et de tonus au quotidien.`,

      `Amélioration de votre tolérance à l’effort : 
      augmentation de votre activité physique et diminution de votre essoufflement.`,

      `Amélioration de votre sommeil et de vos facultés de concentration, d’attention et de mémorisation.`,
      `Amélioration de votre humeur facilitant la relation familiale et le lien social.`,
      `
      Amélioration et soutien de tous vos organes en particulier des fonctions cardiaques 
      et rénales par atténuation des effets de compensation de l’organisme :
      hyperventilation, insuffisance cardiaque, altération de la fonction rénale…
      `,
      `Diminution des périodes d’aggravation de votre maladie respiratoire et d’hospitalisation associée.`,
      `Amélioration significative de votre espérance de vie.`,
    ],
    image: picture,
    reversed: true
  }
]
