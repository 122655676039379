
import React from 'react';
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";

const InfoCard = ({ image, description, reversed,
  title, route, subtitle, subDescription,
  imageTitle, imageDescription, icon, insctructions, ...rest }) => {
  const imageTemplate = (
    <MKBox
      component="img"
      src={image}
      alt={description}
      width="100%"
      my="auto"
    />
  );

  return (
    <Grid container my={5} display='flex' justifyContent='center' alignItems='center'>
      {
        !reversed ?
          <React.Fragment>
            <Grid item xs={12} md={4} >
              {
                (imageTitle || imageDescription) &&
                <React.Fragment>
                  <MKTypography color='primary' mb={2}

                  >{imageTitle}</MKTypography>
                  <MKTypography variant="body2" mb={3}>
                    {imageDescription}
                  </MKTypography>
                </React.Fragment>
              }
              {
                imageTemplate
              }
            </Grid>
            <Grid item xs={12} md={6} sx={{ pl: { md: 2, lg: 8 } }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <MKTypography color='primary' mb={2}

              >{title}</MKTypography>
              {
                icon &&
                <img src={icon} height={40} alt='clock' />
              }
              <MKTypography variant="body2">
                {description}
              </MKTypography>
              {
                (subtitle || subDescription) &&
                <React.Fragment>
                  <MKTypography color='primary' mt={2} mb={1}

                  >{subtitle}</MKTypography>
                  {
                    insctructions &&
                    <Grid display='flex'>
                      {
                        insctructions.map((ins, index) => (
                          <img height={40} key={index} src={ins} style={{ margin: '5px' }} alt='insctruction' />
                        ))
                      }
                    </Grid>
                  }
                  <MKTypography variant="body2">
                    {subDescription}
                  </MKTypography>
                </React.Fragment>
              }
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            <Grid item xs={12} md={6} sx={{ pr: { md: 2, lg: 8 } }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'>
              <MKTypography color='primary' mb={2}
              >{title}</MKTypography>
              <MKTypography variant="body2">
                {description}
              </MKTypography>
              {

              }
            </Grid>
            <Grid item xs={12} md={4}>
              {
                imageTemplate
              }
            </Grid>

          </React.Fragment>
      }

    </Grid>
  );
}

// Setting default props for the InfoCard
InfoCard.defaultProps = {
  description: "",
  reversed: false
};

// Typechecking props for the InfoCard
InfoCard.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string,
  reversed: PropTypes.bool,
};

export default InfoCard;
