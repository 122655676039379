
import React from 'react';
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";

const InfoCard = ({ image, description, reversed, title, route, subtitle, subDescription, elements, ...rest }) => {
  const imageTemplate = (
    <MKBox
      component="img"
      src={image}
      alt={description}
      width="100%"
      my="auto"
    />
  );

  return (
    <Grid container my={5} display='flex' justifyContent='center' alignItems='center'>
      {
        !reversed ?
          <React.Fragment>
            <Grid item xs={12} md={4} >
              {
                imageTemplate
              }
            </Grid>
            <Grid item xs={12} md={6} sx={{ pl: { md: 2, lg: 8 } }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'>
              <MKTypography color='primary' mb={2}

              >{title}</MKTypography>
              <MKTypography variant="body2">
                {description}
              </MKTypography>
              <MKBox component="ul" mx={6} color='white'>
                {
                  elements.map(elem => (
                    <MKBox key={elem} component="li" >
                      <MKTypography
                        variant="body2"
                        fontWeight="light"
                      > {elem}</MKTypography>
                    </MKBox>
                  ))
                }
              </MKBox>
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            <Grid item xs={12} md={6} sx={{ pr: { md: 2, lg: 8 } }}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'>
              <MKTypography color='primary' mb={2}
              >{title}</MKTypography>
              <MKTypography variant="body2">
                {description}
              </MKTypography>
              <MKBox component="ul" mx={6} color='white'>
                {
                  elements.map(elem => (
                    <MKBox key={elem} component="li" >
                      <MKTypography
                        variant="body2"
                        fontWeight="light"
                      > {elem}</MKTypography>
                    </MKBox>
                  ))
                }
              </MKBox>
            </Grid>
            <Grid item xs={12} md={4}>
              {
                imageTemplate
              }
            </Grid>

          </React.Fragment>
      }

    </Grid>
  );
}

// Setting default props for the InfoCard
InfoCard.defaultProps = {
  description: "",
  reversed: false
};

// Typechecking props for the InfoCard
InfoCard.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string,
  reversed: PropTypes.bool,
};

export default InfoCard;
