import React from 'react'
import FacebookIcon from "@mui/icons-material/Facebook";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
const TopHeader = () => {
    return (
        <MKBox bgColor="white" px={5} py={1} display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between">
            <MKTypography variant="body2" color="secondary">
                Astreinte 7j/7 24h/24 : +216 21 69 11 11
            </MKTypography>
            <MKBox display="flex" alignItems="center">
                <MKTypography variant="body2" color="secondary" mr={1} >
                    Nous suivre:
                </MKTypography>
                <MKTypography
                    display="flex"
                    alignItems="center"
                    target="_blank"
                    rel="noreferrer" component="a"
                    href="https://www.facebook.com/azizabdelhayaloui"
                    variant="body2"
                >
                    <FacebookIcon />
                </MKTypography>
            </MKBox>
        </MKBox>
    )
}

export default TopHeader