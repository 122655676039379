import ronflement from '../../../assets/images/icone-ronflements.svg';
import headAche from '../../../assets/images/icone-maux-de-tete.svg';
import fatigue from '../../../assets/images/icone-fatigue.svg';
import memoryProb from '../../../assets/images/icone-difficultes-memoire.svg';
import sweat from '../../../assets/images/icone-sueurs.svg';
import irritable from '../../../assets/images/icone-irritable.svg';

import accident from '../../../assets/images/icone-accident-voiture.svg';
import diabete from '../../../assets/images/icone-diabete-sang.svg';
import cerveau from '../../../assets/images/icone-cerveau.svg';
import rythm from '../../../assets/images/icone-rythme-cardiaque.svg';
import heart from '../../../assets/images/icone-coeur.svg';
import tension from '../../../assets/images/icone-tension-arterielle.svg';

import boxImage from '../../../assets/images/box.jpg';
import marionImage from '../../../assets/images/marion.jpg';
import graph from '../../../assets/images/graph.jpg';
import clock from '../../../assets/images/icone-clock.svg';
import smoking from '../../../assets/images/icone-pas-de-cigarette.svg';
import alcool from '../../../assets/images/icone-zero-alcool.svg';
import walk from '../../../assets/images/icone-marche.svg';
import bicycle from '../../../assets/images/icone-velo.svg';


export const SYMPTOMS = [
  {
    description: 'QUELS SYMPTÔMES ?',
    title: true
  },
  {
    description: 'C’est souvent l’entourage qui s’inquiète !',
    icon: ''
  },
  {
    description: `Le symptôme le plus courant est le ronflement, mais ce n'est pas le seul.`,
    icon: ronflement
  },
  {
    description: `Des maux de têtes au réveil.`,
    icon: headAche,
  },
  {
    description: `Le manque d'énergie, la somnolence durant la journée.`,
    icon: fatigue
  },
  {
    description: `Des difficultés de concentration et des troubles de la mémoire.`,
    icon: memoryProb
  },
  {
    description: `Des sueurs nocturnes.`,
    icon: sweat
  },
  {
    description: `De l'irritabilité, de l'impatience.`,
    icon: irritable
  },
  {
    description: `Mais aussi des envies fréquentes d'aller aux toilettes la nuit, des troubles de la libido.`,
    icon: ''
  },
];

export const CONSEQUENCES = [
  {
    description: 'QUELLES CONSÉQUENCES ?',
    title: true
  },
  {
    description: 'Non traitée, l’apnée du sommeil accroît de nombreux risques pour la santé.',
    icon: ''
  },
  {
    description: `Hypertension artérielle.`,
    icon: tension
  },
  {
    description: `Maladies cardiovasculaires.`,
    icon: heart,
  },
  {
    description: `Troubles du rythme cardiaque.`,
    icon: rythm
  },
  {
    description: `La réalisation d’examens complémentaires tel qu’un scanner thoracique.`,
    icon: cerveau
  },
  {
    description: `Diabète.`,
    icon: diabete
  },
  {
    description: `De l'irritabilité, de l'impatience.`,
    icon: accident
  },

]


export const INFO = [
  {
    title: `Le traitement de référence
     pour l'apnée du sommeil est la mise en place d'un dispositif
      de ventilation à Pression Positive Continue (PPC)`,
    image: boxImage,
    reversed: true,
    description: `
    À l'aide d'un masque adapté aux contours du visage, 
    cet appareil maintient les voies aériennes supérieures ouvertes durant le  sommeil, 
    bloquant le mécanisme de l'apnée, donc, la fermeture anormale des voies aériennes supérieures.
    Ce petit appareil qui peut être posé sur la table de nuit propulse l'air ambiant dans les voies aériennes 
    supérieures par  l'intermédiaire du masque. La pression de l'air empêche la fermeture de celles-ci et la 
    se fait alors normalement.
    `
  },
  {
    title: `Les bénéfices immédiats`,
    subtitle: `Les bénéfices à long terme`,
    image: marionImage,
    reversed: false,
    description: `
    La pression de l’air permet d’écarter les parois pharyngées, 
    empêchant ainsi l’obstruction des voies aériennes supérieures. 
    La  disparition des apnées et hypopnées est donc immédiate. La qualité du sommeil s’améliore,
     avec un sommeil plus continu et plus profond. Dès la première utilisation, une réduction de la somnolence
      diurne et un sommeil beaucoup plus récupérateur sont constatés.
    `,
    subDescription: `
    Une amélioration de la vigilance, un regain de tonus et d’énergie, 
    une diminution du risque des accidents liés à la somnolence et la fatigue se confirment.`
  },
  {
    title: `Règles de bonne conduite`,
    icon: clock,
    imageTitle: `Suivi quotidien de votre traitement`,
    imageDescription: `Avec Médopale, suivez votre traitement au quotidien depuis notre site ou via une application smartphone.`,
    image: graph,
    reversed: false,
    description: `
    Afin d'obtenir un réel bénéfice, il est important d'utiliser l'appareil quotidiennement 
    et pendant toute la durée du sommeil. Une observance optimale permet d’obtenir un réel bénéfice du traitement. 
    La sécurité sociale tient d’ailleurs compte de la durée d’utilisation pour le remboursement.
    `,
    subDescription: `
    Pour traiter l’apnée du sommeil, il est également primordial 
    de contrôler son poids et d’adopter une bonne hygiène de vie.`,
    instructionsIcons: [
      smoking,
      alcool,
      walk,
      bicycle
    ]
  },

]