import img from '../../../../assets/images/apnee.jpeg';
import img1 from '../../../../assets/images/oxygeno.jpg';

const data = [
  {
    image: img,
    title: 'APNÉE DU SOMMEIL',
    description: `
    Ronflements, somnolence, manque d’énergie ?
    L’apnée est un trouble du sommeil courant, qui se manifeste par des pauses respiratoires durant le sommeil. 
    On parle d’apnée lorsque la respiration s’arrête durant au moins 10 secondes.
     `,
    route: "/sleep-apnea",
    reversed: true
  },
  {
    image: img1,
    title: 'OXYGENOTHERAPIE',
    description: `
    L’oxygénothérapie est indiquée dans le traitement des insuffisances respiratoires qui se 
    traduit par l’incapacité des poumons à assurer l’oxygénation correcte de l’organisme.
    `,
    route: "oxygen-therapy",
    reversed: false
  },
];

export default data; 